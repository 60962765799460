@import './global.scss';

.footer {
  width: 100vw;
  min-height: 300px;
  // @include flex();
  flex-direction: column;
  background: #0D1117;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding-top: 40px;

  .footer-layout {
    @include screenLayout();
    @include flex(space-between);
    align-items: flex-start;
    flex-wrap: nowrap;

    .footer-nav {
      @include flex(flex-start);
      align-items: flex-start;

      >li {
        margin-right: 96px;

        >p {
          color: #FFFFFF;
          font-size: 20px;
          margin-bottom: 25px;
        }

        >div {

          p,
          a {
            color: #878E99;
            margin-bottom: 16px;
            cursor: pointer;
          }
        }
      }
    }

    .logo-text {
      text-align: right;
      .logo {
        height: 46px;
        margin-bottom: 23px;
      }

      p {
        line-height: 2;
        color: #878E99;
      }
      .icons{
        a{
          margin-right: 30px;
          img{
            width: 25px;
          }
          &:last-child{
            margin-right: 0;
          }
        }

      }
    }
  }

  &.extra {
    top: 1200px;
  }
  .copyright{
    @include heightLine(50px);
    width: 100%;
    border-top: 1px solid #1D2229;
    text-align: center;
    color: #878E99;
    margin: 40px 0 0;
  }
}