@import './global.scss';

.register,
.login,
.forgot {
  width: 100%;

  .footer {
    top: 1200px;
  }
}

.user {
  margin: 150px auto 0;
  width: 496px;
  border-radius: 24px;
  padding: 32px;
  border: 1px solid #11171F;
  box-shadow: 0 2px 12px rgba(#000000, 0.9);
  background: #11171F;

  .title {
    color: #FFFFFF;
    font-size: 22px;
    text-align: center;
    margin-bottom: 32px;
  }

  .user-form {
    .form-item-name {
      color: #FFFFFF;
    }

    .ant-form-item {
      margin: 12px 0 50px;

      .ant-form-item-control-input-content {
        background: #00070F;
        border-radius: 12px;
        border: 1px solid #343C47;
        padding: 0 16px;

        .ant-input-affix-wrapper {
          background: #00070F;
          border: none;
          &:focus{
            box-shadow: none !important;
          }
          .ant-input-suffix {
            svg {
              color: #FFF;
            }
          }
        }

        .ant-input-status-error,
        .ant-input-affix-wrapper-status-error {
          background: #00070F;
          border: none;

          &:hover,
          &:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
          .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
            background: #00070F;
            box-shadow: none;
          }
        }

      }

      .ant-input {
        background: #00070F;

        &::-webkit-input-placeholder {
          color: #5C6673;
        }
      }
    }

    .tip-info {
      width: 100%;
      @include flex(space-between);
      margin-bottom: 20px;

      >div,
      >a {
        cursor: pointer;
        color: #F04327;
      }
    }

    .logon-box{
      @include flex();
      flex-wrap: nowrap;
      width: 100%;
      justify-content: space-between;
      .regs-btn{
        height: 40px;
        width: 87%;
        border-radius: 4px;
        background: #F04327;
        border: none;
        box-shadow: none;
        color: #FFF;
        cursor: pointer;
      }
      &.block{
        display: block;
        .regs-btn{
          width: 100%;
          border-radius: 8px;
        }
      }
    }

    .to-logo-info {
      margin-top: 30px;
      color: #FFF;
      text-align: center;

      >a {
        color: #F04327;
      }
    }
  }

}