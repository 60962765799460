@import './global.scss';

.header {
  @include flex(space-between);
  flex-wrap: nowrap;
  $height: 60px;
  width: 100%;
  height: $height;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  padding: 0 40px;
  white-space: nowrap;
  background: #000;
  transition: all 1s;

  &.none {
    opacity: 0;
    transition: all 1s;
  }

  .nav-left {
    .logo {
      height: 36px;
    }
  }

  .nav-right {
    >ul {
      @include flex();
      flex-wrap: nowrap;

      li {
        cursor: pointer;
        margin-right: 36px;
        color: $font-color;
        font-size: 16px;

        >a {
          color: $font-color;

          &.head-git-icon {
            display: inline-block;
            margin: 0 0 0 20px;
            cursor: pointer;
            width: 24px;
            overflow: hidden;
            white-space: nowrap;
            vertical-align: top;

            >img {
              width: 100%;
              position: relative;
              z-index: 10;
            }

            &:hover {
              img {
                &:last-child {
                  transform: translateX(-100%);
                }
              }
            }

          }
        }

        &.login-btn {
          >a {
            color: #F04327;
            display: inline-block;
            height: 40px;
            line-height: 36px;
            padding: 0 42px;
          }

          border: 1px solid #F04327;
          border-radius: 8px;
          color: #F04327;
          margin-right: 20px;
        }

        // &.login-btn {
        //   color: $font-color;
        //   margin: 0 32px 0 20px;
        //   background: #F04327;

        //   >a {
        //     color: white;
        //   }
        // }

        >a {
          color: white;
        }
      }

      .language-icon {
        display: inline-block;
        cursor: pointer;
        width: 24px;
        // height: 24px;
        overflow: hidden;
        white-space: nowrap;
        vertical-align: top;

        >img {
          width: 100%;
          position: relative;
          z-index: 10;
        }

        &:hover {
          img {
            &:last-child {
              transform: translateX(-100%);
            }
          }
        }
      }

      .current-info {
        @include flex();
        width: 180px;
        height: 40px;
        background-color: #F04327;
        border-radius: 8px;
        cursor: pointer;
        color: #FFF;
        margin: 0 24px;
      }
    }
  }

}

.ant-popover {

  &.ant-popover-lan,
  &.ant-popover-user {
    .ant-popover-content {

      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner {
        border-radius: 12px;

        .ant-popover-inner-content {
          padding: 4px;

          .popover-list {
            li {
              @include heightLine(36px);
              @include flex();
              justify-content: flex-start;
              min-width: 88px;
              cursor: pointer;
              background-color: #FFF;
              color: rgb(136, 136, 136);
              transition: all 0.3s;
              border-radius: 2px;
              padding: 0 5px;
              padding: 0 15px;

              img {
                $size: 22px;
                width: $size;
                height: $size;
                margin-right: 10px;
              }


              &:hover {
                background: #ececec;
                color: #000;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }

  }

  &.ant-popover-user {
    li {
      text-align: center;
    }
  }

}