@import './global.scss';

.customer-box {
  width: 100vw;
  // height: 1064px;
  height: 850px;
  background: #00070F url('~@/assets/images/custome-bg.png') top center no-repeat;

  .customer {
    @include screenLayout();
    // padding: 100px 0;
    margin-bottom: 100px;

    .title {
      text-align: center;
      color: #FFFFFF;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 72px
    }

    .box {
      @include flex(flex-start);

      >div {

        >a,
        >div {
          @include flex();
          cursor: pointer;
          width: 278px;
          height: 80px;
          margin-bottom: 24px;
          background: #00070F;
          border-radius: 12px;
          border: 1px solid #272A2E;
          box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.5) inset;

          >img {
            transform: scale(0.5, 0.5);
          }

          >span {
            margin-left: 8px;
            color: #C7C7C7;
            font-size: 24px;
            font-weight: bold;
          }

          margin-right: 16px;

          &:last-child {
            color: #878E99;
            font-size: 16px;
          }

          &:hover {
            background: #171B21;
          }
        }

      }

    }
  }

  .universe {
    @include screenLayout();
    @include flex(space-between);
    flex-wrap: nowrap;
    margin-bottom: 30px;

    .title-btn {
      .title {
        color: #FFFFFF;
        font-size: 36px;
        margin-bottom: 24px;
      }

      .btn {
        cursor: pointer;
        width: 240px;
        height: 40px;
        @include flex();
        background: #F04327;
        border-radius: 8px;
        color: #FFFFFF;
        font-size: 16px;

        &.disable {
          background: #22262D;
          color: #878E99;
          cursor: not-allowed;
        }
      }
    }

    .universe-img {
      width: 706px;
    }


  }

  ul {
    @include screenLayout();
    @include flex(space-between);

    li {
      p {
        @include flex();
        font-size: 18px;
        color: #FFFFFF;

        span {
          font-size: 36px;
          font-weight: bold;
          color: #F04327;
        }

        img {
          margin-left: 8px;
          height: 24px;
        }
      }
    }
  }
}