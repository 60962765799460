@import './global.scss';

.product-canvas {
  background: #00070F;
  min-height: 1228px;
  position: relative;

  .product-start {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    width: 25vw;
  }

  .product-box {
    min-width: 1200px;
    @include screenLayout();
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translate(-50%, 0);

    .title {
      text-align: center;
      margin-bottom: 50px;

      >p {
        display: block;
        font-size: 36px;
        font-weight: bold;
        color: #FFFFFF;
      }

      >span {
        color: #878E99;
        font-size: 16px;
      }
    }

    .box {
      @include flex();

      >div {
        cursor: pointer;
        @include flex();
        flex-direction: column;
        width: 525px;
        height: 420px;
        margin-bottom: 30px;
        padding: 0 50px;

        >p {
          display: block;
          color: #F04327;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }

        >span {
          display: inline-block;
          text-align: center;
          color: #878E99;
          font-size: 15px;
        }

        &:nth-child(2n -1) {
          margin-right: 30px;
        }

        &:first-child {
          img {
            width: 200px;
          }

          p {
            margin: 80px 0 38px;
          }
        }

        &:nth-child(2) {
          p {
            margin: 40px 0 30px;
          }

          img {
            width: 128px;
          }
        }

        &:nth-child(3) {
          p {
            margin: 30px 0;
          }

          span {
            font-size: 14px;
            line-height: 1.8;
          }

          img {
            width: 130px;
          }
        }

        &:nth-child(4) {
          img {
            width: 98px;
          }
          .en{
            margin-top: -58px;
          }

          p {
            margin: 22px 0 32px;
          }
        }

        &:hover {
          border-radius: 20px;
          background: #0D1117;
          border: 1px solid #272A2E;
          box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.5);
        }
      }
    }
  }
}