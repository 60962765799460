$font-color : #FFFFFF;
$sub-color :#878E99 ;


/* 
 * @mixin 定义公共样式函数
 * @extend 继承某个class样式，可继承多个
 * $justify 外部注入参数，可定义/接收多个参数，也可设置默认参数
 * 其他文件引用，例 @include flex(center); */

/*
 * 多个参数(不确定)传递可用Variable Arguments参数形式接收
 * 例 @include boxShadow(0 0 10px red inset, 0 0 10px blue);*/

@mixin flex($justify: center) {
  display: flex;
  align-items: center;
  justify-content: $justify;
  flex-wrap: wrap;
}

@mixin heightLine($height) {
  height: $height;
  line-height: $height;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin boxShadow($shadows: 0 0 10px rgba($shadow-color, 0.1)) {
  box-shadow: $shadows;
}

@mixin colorStyle($color, $line-color: $color) {
  background: $color;
  border-color: $line-color;
}

@mixin linearGradient($direction, $colors...) {
  background: linear-gradient($direction, $colors);
}

@function checkColor($theme) {

  @if $theme=='dark' {
    @return $font-color;
  }

  @else {
    @return $light-color;
  }
}

@mixin screenLayout {
  width: 1200px;
  margin: 0 auto;
  // overflow-x: hidden;
}

@mixin mContainer($paddding: null) {
  @include boxShadow();

  @if $paddding {
    padding: $paddding
  }

  @else {
    padding: 0.4rem 0.32rem;
  }

  background: $light-color;
  border-radius: 0.12rem;
}

@mixin mTopBar() {
  position: relative;
  height: 3.25rem;
  padding: 0.2rem 0 0;
  background: $theme-color;
}

@mixin flexLastStyle($marginLeft: 1rem) {
  @include flex(flex-end);
  flex: auto;
  max-width: 0;
  margin-left: $marginLeft;
  white-space: nowrap;
}

@mixin listBgStyle($padding: 0.4rem 0.32rem) {
  @include linearGradient(180deg, $light-color, rgba($light-color, 0) 6rem);
  padding: $padding;
}