@import '~antd/dist/antd.css';
#root {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background: #00070F;
  padding: 0 0 300px;
}


ol, ul, dl {
  margin-bottom: 0
}
h1{
  margin-bottom: 0;
  line-height: 1.2;
}

//全局样式.ant-form {

  .ant-form-item {
    cursor: pointer;

    .ant-input {
      border: none;
      height: 48px;
      color: #FFF;

      &::placeholder {
        color: #5C6673;
      }

      &:focus,
      .ant-input-focused {
        box-shadow: none;
      }
    }

    .ant-input-disabled {
      cursor: pointer;
    }

  }

  .ant-form-item-has-error {
  .ant-form-item-explain-error{
    color: red;
  }

  }
