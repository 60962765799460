@import './global.scss';

.serve-box {
  width: 100vw;
  background: #00070F;
 
  .title {
    @include screenLayout();
    text-align: center;
    margin-bottom: 57px;

    >p {
      color: #FFFFFF;
      font-size: 36px;
    }

    >span {
      color: #878E99;
      font-size: 18px;
    }
  }

  .box {
    @include screenLayout();
    @include flex();

    .approach {
      position: relative;
      cursor: pointer;

      .content {
        @include flex();
        position: relative;
        padding: 35px 55px 44px 55px;
        height: 295px;
        width: 436px;
        border-radius: 16px;
        background: linear-gradient(to bottom, #00070F, #11171F);
        box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.5);
        border: 1px solid #272A2E;

        >div {
          width: 100%;
          @include flex(space-between);

          p {
            color: #FFF;
            font-size: 24px;
            font-weight: bold;
          }

          &:first-child {
            img {
              height: 40px;
            }
          }

          &:nth-child(2) {
            img {
              height: 50px;
            }
          }
        }

        .sec-line {
          color: #878E99;
          font-size: 15px;
        }

        .th-line {
          border: 1px solid #F04327;
          border-radius: 8px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          @include flex();
          color: #F04327;
          font-size: 16px;

          &:hover {
            color: #FFF;
            background: #F04327;
          }
        }

        &:first-child {
          margin-right: 45px;
        }

      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 295px;
        width: 436px;
        border-radius: 16px;
        background: #F04327;
      }

      &:hover {
        &::before {
          animation: rotate .5s forwards;
        }
      }
      @keyframes rotate {
        0% {
          transform: translate(0, 0);
        }
      
        100% {
          transform: translate(14px, 14px);
        }
      }
    }


  }


}