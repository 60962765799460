@import './global.scss';

.solution-box {
  @include screenLayout();
  padding-bottom: 100px;
  height: 430px;
  margin-top: 100px;

  .introduction {
    text-align: center;

    >p {
      color: $font-color;
      font-size: 36px;
      font-weight: bold;
    }

    >span {
      display: inline-block;
      color: $sub-color;
      font-size: 16px;
    }
  }

  .box {
    @include flex();
    margin-top: 88px;
    height: 260px;

    >img {

      &:first-child {
        width: 1100px;
      }

    }
  }
}