@import './global.scss';

.core-info {
  color: $font-color;
  @include screenLayout();
  @include flex();
  flex-direction: column;
  align-items: flex-start;

  .core {
    margin-top: 230px;
    h1 {
      font-size: 60px;
      font-weight: bold;
      color: $font-color;
    }

    >div {
      color: $sub-color;
      font-size: 20px;
    }
  }

  .registered {
    margin: 68px 0 110px 0;

    .reg-btn {
      cursor: pointer;
      padding: 15px 0;
      width: 234px;
      text-align: center;
      background: #F04327;
      border-radius: 11px;
      &.disable{
        background: #22262D;
        color: #878E99;
        cursor: not-allowed;
      }
    }

    .text {
      color: $sub-color;
      font-size: 12px;
      margin-top: 13px;
    }
  }

  .advantages {
    @include flex();
    flex-wrap: nowrap;
    z-index: 2;
    border-radius: 18px;
    border: 0.3px solid #272A2E;
    background: rgba(25, 27, 31, 0.9);
    // height: 176px;
    backdrop-filter: blur(4px);
    transition: background-color 0.25s ease 0s;

    >li {
      cursor: pointer;
      @include flex(flex-start);
      // height: 176px;
      position: relative;
      box-sizing: border-box;
      width: 413.33px;
      padding: 0 30px;
      flex-direction: column;
      border-right: 1px solid #272A2E;

      >p {
        font-size: 20px;
        font-weight: bold;
        color: $font-color;
        margin-bottom: 25px;
        padding-top: 40px;
        text-align: center;
      }

      >div {
        font-size: 12px;
      }

      >span {
        display: inline-block;
        color: $sub-color;
        font-size: 12px;
        text-align: center;
        padding-bottom: 40px;
      }

      &:nth-child(2) {
        >p {
          margin-bottom: 0;
        }

        >span {
          margin-top: 8px;
        }
      }

      &:nth-child(3) {
        border-right: none;
        border-radius: 0 18px 18px 0;

      }

    }
  }
}