@import './global.scss';

.home {
  .box-top {
    height: 1650px;
    width: 100vw;
    background: #00070F url('~@/assets/images/bg-1.png') top center no-repeat;
    background-size: 100vw 1650px;
    position: relative;

    .semicircle {
      width: 18vw;
      position: absolute;
      top: 0;
      left: 0;
    }

    .circular {
      position: absolute;
      right: -230px;
      // right: -10%;
      top: 5%;

      >svg {
        >image {
          height: 843px;
        }
      }
    }

    .line {
      height: 832px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}